import React, { ReactElement } from 'react';
import {
  DateField,
  Filter,
  FunctionField,
  TextField,
  useStore,
} from 'react-admin';
import CertStatusWithColorDotField from '../common/CertStatusWithColorDotField';
import ActivityDurationUnitField from '../common/ActivityDurationUnitField';
import GenerateCertificateButton from '../common/GenerateCertificateButton';
import DatagridConfigurableRbac from '../common/DatagridConfigurableRbac';
import renderMetaDataFields from '../common/helpers/renderMetaDataFields';
import ActivityConstraintField from '../common/ActivityConstraintField';
import FreeTextSearchFilter from '../common/FreeTextSearchFilter';
import useDefaultFilter from '../common/hooks/useDefaultFilter';
import { getSideFiltersKey } from '../common/helpers/storeKeys';
import ActivityPhaseField from '../common/ActivityPhaseField';
import AssignedField from '../common/Assigned/AssignedField';
import ActivityTypeField from '../common/ActivityTypeField';
import CertTemplateField from '../common/CertTemplateField';
import { RESOURCE_ACTIVITY } from '../constants';
import DisciplineField from '../common/DisciplineField';
import DownloadButton from '../common/DownloadButton';
import PreviewButton from '../common/PreviewButton';
import ColumnActions from '../common/ColumnActions';
import ListWithTitle from '../common/ListWithTitle';
import ListActions from '../common/ListActions';
import ActivityField from '../common/ActivityField';
import ActivityCategoryInput from '../common/ActivityCertificate';
import SelectField from '../common/SelectField';
import { ACTIVITY_CATEGORY_OPTIONS } from '../common/constants';
import ActivityShow from './ActivityShow';
import ActivityEdit from './ActivityEdit';
import BulkActionButtons from './components/BulkActionButtons';
import ActivitySideFilters from './components/ActivitySideFilters';
import CategoryNameField from './components/CategoryNameField';
import { ACTIVITY_EXPORT_BUTTONS } from './constants';
import CategoryDescriptionField from './components/CategoryDescriptionField';
import PredecessorActivityField from './components/PredecessorActivityField';

const ActivityList: React.FC = (): ReactElement => {
  const sideFilter = getSideFiltersKey(RESOURCE_ACTIVITY);
  const [showSideFilter] = useStore(sideFilter, false);
  const defaultFilter = useDefaultFilter('project');

  return (
    <ListWithTitle
      filters={
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <FreeTextSearchFilter />
          <Filter>
            <ActivityCategoryInput
              multiple
              fullWidth
              alwaysOn
              TextFieldProps={{ InputProps: { sx: { height: 40 } } }}
            />
          </Filter>
        </div>
      }
      filterDefaultValues={defaultFilter}
      actions={
        <ListActions
          sideFilter={sideFilter}
          defaultFilter={defaultFilter}
          multipleExportButtonsInfo={ACTIVITY_EXPORT_BUTTONS}
        />
      }
      aside={
        showSideFilter ? (
          <ActivitySideFilters defaultFilter={defaultFilter} />
        ) : null
      }
      sort={{ field: 'activity', order: 'ASC' }}
    >
      <DatagridConfigurableRbac
        ShowComponent={<ActivityShow withoutActions />}
        EditComponent={<ActivityEdit syncWithLocation={false} />}
        bulkActionButtons={<BulkActionButtons />}
        defaultVisibleColumns={[
          'id',
          'certGatingStatus.certStatus',
          'activity',
          'description',
          'category',
          'sequence',
          'activityPhaseId',
          'disciplineId',
          'activityTypeId',
          'actions',
        ]}
      >
        <TextField source="id" label="ID" />
        <CertStatusWithColorDotField />
        <TextField source="activity" label="Activity" />
        <TextField source="description" label="Description" />
        <SelectField
          choices={ACTIVITY_CATEGORY_OPTIONS}
          source="category"
          label="Category"
        />
        <TextField source="sequence" label="Sequence" />
        <ActivityPhaseField />
        <DisciplineField />
        <ActivityTypeField />
        <CategoryNameField />
        <CategoryDescriptionField />
        <CertTemplateField />
        <ActivityConstraintField />
        <PredecessorActivityField sortable={false} />
        <ActivityField
          label="Parent Activity"
          resource={RESOURCE_ACTIVITY}
          activitySource="parentActivity.activity"
          source="parentId"
        />
        <TextField source="duration" label="Duration" />
        <TextField source="actualDuration" label="Actual Duration" />
        <ActivityDurationUnitField />
        <DateField source="plannedStartDate" label="Planned Start Date" />
        <DateField source="actualStartDate" label="Actual Start Date" />
        <DateField source="plannedDate" label="Planned Completion Date" />
        <DateField source="actualDate" label="Actual Completion Date" />
        <TextField source="percentComplete" label="Progress" />
        <TextField
          source="certDetails.certTotalSignatures"
          label="Cert Total Signatures"
          sortable={false}
        />
        <TextField
          source="certDetails.certSigned"
          label="Cert Signed"
          sortable={false}
        />
        <TextField
          source="certDetails.certSignedBy"
          label="Cert Signed By"
          sortable={false}
        />
        <AssignedField
          sourcePrefix="certDetails"
          label="Certificate Responsible"
          sortable={false}
        />
        <TextField
          source="certGatingStatus.itrStatus"
          label="ITR Status"
          sortBy="certGatingStatus.itr_status"
        />
        <TextField
          source="certGatingStatus.punchStatus"
          label="Punch Status"
          sortBy="certGatingStatus.punch_status"
        />
        <TextField
          source="certGatingStatus.certificateStatus"
          label="Predecessor Certificate Status"
          sortBy="certGatingStatus.certificate_status"
        />
        {renderMetaDataFields()}
        <FunctionField
          label="Actions"
          render={(record) => {
            return (
              <ColumnActions label="Actions">
                <GenerateCertificateButton activityCategory={record.category} />
                <PreviewButton />
                <DownloadButton />
              </ColumnActions>
            );
          }}
        />
      </DatagridConfigurableRbac>
    </ListWithTitle>
  );
};

export default ActivityList;
