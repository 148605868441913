import React, { ReactElement, useEffect } from 'react';
import { maxLength, useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import { useFormContext } from 'react-hook-form';
import { required } from '../../../utils/UtilityFunctions';
import ActivityConstraintInput from '../../common/ActivityConstraintInput';
import ActivityDurationUnitInput from '../../common/ActivityDurationUnitInput';
import ActivityPhaseInput from '../../common/ActivityPhaseInput';
import ActivityTypeInput from '../../common/ActivityTypeInput';
import CertTemplateInput from '../../common/CertTemplateInput';
import commonStyles from '../../common/commonStyles';
import { activityDurationUnit } from '../../common/constants';
import CustomDateInput from '../../common/CustomDateInput';
import CustomNumberInput from '../../common/CustomNumberInput';
import DisciplineInput from '../../common/DisciplineInput';
import TextInput from '../../common/TextInput';
import ActivityCategoryInput from '../../common/ActivityCertificate';
import ActivityInput from './ActivityInput';
import EntityInput from './EntityInput';

interface SystemActivityFormProps {
  bulkEditMode?: boolean;
}

const ActivityForm: React.FC<SystemActivityFormProps> = ({
  bulkEditMode,
}): ReactElement => {
  const isRequired = !bulkEditMode;
  const { setValue, watch } = useFormContext();
  const category = watch('category');
  const record = useRecordContext();

  useEffect(() => {
    setValue('certTemplateId', null);
    setValue('systemId', null);
    setValue('subsystemId', null);
    setValue('componentId', null);
  }, [setValue, category]);

  return (
    <Box sx={commonStyles.columnFlexBox}>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="activity"
          source="activity"
          label="Activity"
          sx={commonStyles.flexBoxItem}
          validate={maxLength(75)}
          disabled={bulkEditMode}
        />
        <ActivityCategoryInput
          sx={commonStyles.flexBoxItem}
          readOnly={Boolean(record?.id) || bulkEditMode}
          isRequired={isRequired}
        />
        <EntityInput category={category} isRequired={isRequired} />
        <ActivityPhaseInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="description"
          source="description"
          label="Description"
          sx={commonStyles.flexBoxLongItem}
          validate={[maxLength(255), ...(!bulkEditMode ? [required()] : [])]}
          multiline
        />
        <DisciplineInput
          sx={commonStyles.flexBoxItem}
          withAllOption
          isRequired={isRequired}
        />
        <ActivityTypeInput
          sx={commonStyles.flexBoxItem}
          isRequired={isRequired}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <TextInput
          name="sequence"
          source="sequence"
          label="Sequence"
          sx={commonStyles.flexBoxItem}
          validate={[maxLength(20), ...(!bulkEditMode ? [required()] : [])]}
          disabled={bulkEditMode}
        />
        <ActivityConstraintInput sx={commonStyles.flexBoxItem} />
        <CertTemplateInput
          category={category}
          disabled={!category}
          sx={commonStyles.flexBoxItem}
        />
        <ActivityInput
          source="parentId"
          label="Parent Activity"
          idToFilterOut={record?.id}
          optionText={(option) => `${option.id}::${option.activity}`}
          sx={commonStyles.flexBoxItem}
        />
      </Box>
      <Box sx={commonStyles.flexBoxWithWrap}>
        <CustomNumberInput
          name="duration"
          source="duration"
          label="Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <CustomNumberInput
          name="actualDuration"
          source="actualDuration"
          label="Actual Duration"
          sx={commonStyles.flexBoxItem}
          isPositive
        />
        <ActivityDurationUnitInput
          sx={commonStyles.flexBoxItem}
          defaultValue={activityDurationUnit.day}
          isRequired={true}
        />
        <CustomNumberInput
          name="percentComplete"
          source="percentComplete"
          label="Progress"
          max={100}
          sx={commonStyles.flexBoxItem}
          isPositive
        />
      </Box>
      <Box sx={{ ...commonStyles.flexBoxWithWrap, marginBottom: '20px' }}>
        <CustomDateInput
          disabled={Boolean(record?.childActivities?.length)}
          name="plannedStartDate"
          source="plannedStartDate"
          label="Planned Start Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          name="actualStartDate"
          source="actualStartDate"
          label="Actual Start Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          disabled={Boolean(record?.childActivities?.length)}
          name="plannedDate"
          source="plannedDate"
          label="Planned Completion Date"
          sx={commonStyles.flexBoxItem}
        />
        <CustomDateInput
          name="actualDate"
          source="actualDate"
          label="Actual Completion Date"
          sx={commonStyles.flexBoxItem}
        />
      </Box>
    </Box>
  );
};

export default ActivityForm;
