import React, { ReactElement } from 'react';
import { Labeled, TabbedShowLayout, TextField } from 'react-admin';
import Box from '@mui/material/Box';
import ActivityConstraintField from '../common/ActivityConstraintField';
import ActivityDurationUnitField from '../common/ActivityDurationUnitField';
import ActivityPhaseField from '../common/ActivityPhaseField';
import ActivityTypeField from '../common/ActivityTypeField';
import CertDetailsShowTab from '../certDetails/CertDetailsShowTab';
import CertTemplateField from '../common/CertTemplateField';
import commonStyles from '../common/commonStyles';
import DisciplineField from '../common/DisciplineField';
import renderShowMetaDataFields from '../common/helpers/renderShowMetaDataFields';
import ShowComponent, { ShowComponentProps } from '../common/ShowComponent';
import AuditListTab from '../audit/AuditListTab';
import SelectField from '../common/SelectField';
import { ACTIVITY_CATEGORY_OPTIONS } from '../common/constants';
import ActivityField from '../common/ActivityField';
import { RESOURCE_ACTIVITY } from '../constants';
import AttachmentList from '../attachment/AttachmentList';
import { ATTACHMENT_TYPE_ENUMS } from '../common/enums';
import CategoryNameField from './components/CategoryNameField';
import PredecessorActivitiesEditTab from './components/PredecessorActivitiesEditTab';

const ActivityShow: React.FC<ShowComponentProps> = ({
  syncWithLocation,
  ...rest
}): ReactElement => (
  <ShowComponent {...rest}>
    <TabbedShowLayout
      sx={commonStyles.bottomMargin}
      syncWithLocation={syncWithLocation}
    >
      <TabbedShowLayout.Tab label="Activity">
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="activity" label="Activity" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <SelectField
              choices={ACTIVITY_CATEGORY_OPTIONS}
              source="category"
              label="Category"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CategoryNameField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="sequence" label="Sequence" />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityPhaseField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <DisciplineField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityTypeField />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxLongItem}>
            <TextField source="description" label="Description" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityConstraintField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <CertTemplateField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityField
              label="Parent Activity"
              resource={RESOURCE_ACTIVITY}
              activitySource="parentActivity.activity"
              source="parentId"
            />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="duration" label="Duration" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="actualDuration" label="Actual Duration" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <ActivityDurationUnitField />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="percentComplete" label="Progress" />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="plannedStartDate" label="Planned Start Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="actualStartDate" label="Actual Start Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="plannedDate" label="Planned Completion Date" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="actualDate" label="Actual Completion Date" />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField source="certGatingStatus.itrStatus" label="ITR Status" />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="certGatingStatus.punchStatus"
              label="Punch Status"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="certGatingStatus.certificateStatus"
              label="Predecessor Certificate Status"
            />
          </Labeled>
          <Labeled sx={commonStyles.flexBoxItem}>
            <TextField
              source="certGatingStatus.certStatus"
              label="Cert Gating Status"
            />
          </Labeled>
        </Box>
        <Box sx={commonStyles.flexBoxWithWrap}>
          {renderShowMetaDataFields()}
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Predecessor Activities"
        id="predecessor_activities_tab"
      >
        <PredecessorActivitiesEditTab showMode />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Certificates" id="certificates_tab">
        <CertDetailsShowTab target="activityId">
          <CategoryNameField />
        </CertDetailsShowTab>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label="Attachment"
        id="attachment_tab"
        sx={commonStyles.formTabHeader}
      >
        <AttachmentList
          targetId="activityId"
          resourceName={RESOURCE_ACTIVITY}
          attachmentType={[
            ATTACHMENT_TYPE_ENUMS.GENERAL,
            ATTACHMENT_TYPE_ENUMS.IMAGE,
          ]}
          onlyShow
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="History">
        <AuditListTab tableName="tbl_activity" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </ShowComponent>
);

export default ActivityShow;
