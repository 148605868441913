import React, { ReactElement, ReactNode, useEffect } from 'react';
import {
  DatagridConfigurable,
  DatagridProps,
  usePermissions,
  useRecordContext,
  useResourceContext,
  useStore,
  useListContext,
} from 'react-admin';
import isEqual from 'lodash/isEqual';
import { hasFieldShowAccess } from '../../utils/UtilityFunctions';
import useCanAccess from './hooks/useCanAccess';
import useDidUpdateEffect from './hooks/useDidUpdateEffect';
import useUploadDatagridSettings from './hooks/useUploadDatagridSettings';

interface DatagridConfigurableRbacProps extends DatagridProps {
  children: ReactNode[];
  EditComponent?: ReactElement;
  ShowComponent?: ReactElement;
  defaultVisibleColumns?: string[];
  preferenceKey?: string;
  checkFieldsAccess?: boolean;
}

const DatagridConfigurableRbac: React.FC<DatagridConfigurableRbacProps> = ({
  children,
  EditComponent,
  ShowComponent,
  defaultVisibleColumns,
  preferenceKey,
  checkFieldsAccess,
  ...rest
}): ReactElement => {
  const resource = useResourceContext();
  const { permissions } = usePermissions();
  const resourceAccessList = useCanAccess();
  const listContext = useListContext();
  const uploadDatagridSettings = useUploadDatagridSettings();
  const finalPreferenceKey = preferenceKey || `${resource}.datagrid`;
  let visibleChildren = children;

  const [expanded, setExpanded] = useStore(`${resource}.datagrid.expanded`, []);

  const [currentAvailableColumns] = useStore(
    `preferences.${finalPreferenceKey}.availableColumns`,
    []
  );
  const [defaultAvailableColumns, setDefaultAvailableColumns] = useStore(
    `preferences.${resource}.datagrid.availableColumns`,
    []
  );
  const [customAvailableColumns] = useStore(
    `preferences.${resource}.dict.availableColumns`,
    []
  );
  const [, setOmit] = useStore(`preferences.${finalPreferenceKey}.omit`, []);
  const [defaultColumns, setDefaultColumns] = useStore(
    `preferences.${resource}.datagrid.columns`,
    []
  );
  const [currentColumns, setCurrentColumns] = useStore(
    `preferences.${finalPreferenceKey}.columns`,
    []
  );

  // if expanded item is not in current data, reset `expanded` to show title from ListTitle.tsx instead of EditTitle.tsx
  useEffect(() => {
    const dataIds: number[] = listContext.data?.map((item) => item.id) || [];
    if (listContext.data && !dataIds.some((id) => expanded.includes(id))) {
      setExpanded([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(listContext.data), setExpanded]);

  const flattenChildren = (children) => {
    return children.reduce((acc, child) => {
      if (Array.isArray(child)) {
        acc.push(...flattenChildren(child));
      } else {
        acc.push(child);
      }
      return acc;
    }, []);
  };

  if (checkFieldsAccess) {
    visibleChildren = flattenChildren(children)
      .map((child: ReactElement) => {
        // Skip field permission check for related sources
        if (!child?.props?.source || child?.props?.source.includes('.')) {
          return child;
        }
        if (
          hasFieldShowAccess(
            permissions,
            `${resource}.${(child as ReactElement)?.props?.source}`
          )
        ) {
          return child;
        }

        return null;
      })
      .filter(Boolean);
  }

  useEffect(() => {
    const order = [...customAvailableColumns].map((item) => item.index);
    const reorderedDefaultColumns = [...defaultAvailableColumns].sort(
      (a, b) => order.indexOf(a.index) - order.indexOf(b.index)
    );
    if (!isEqual(reorderedDefaultColumns, defaultAvailableColumns)) {
      setDefaultAvailableColumns(reorderedDefaultColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(customAvailableColumns)]);

  useEffect(() => {
    if (!isEqual(currentColumns, defaultColumns)) {
      setDefaultColumns(currentColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentColumns)]);

  useEffect(() => {
    const columnsToShow = [];

    if (defaultVisibleColumns && defaultVisibleColumns.length) {
      currentAvailableColumns.forEach((availableColumn) => {
        if (
          (availableColumn.source &&
            defaultVisibleColumns.includes(availableColumn.source)) ||
          !availableColumn.source
        ) {
          columnsToShow.push(availableColumn.index);
        }
      });
    }

    if (columnsToShow.length && !currentColumns.length) {
      setCurrentColumns(columnsToShow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentAvailableColumns)]);

  useEffect(() => {
    const columnsToOmit = [];

    if (defaultVisibleColumns && defaultVisibleColumns.length) {
      currentAvailableColumns.forEach((availableColumn) => {
        if (
          availableColumn.source &&
          !defaultVisibleColumns.includes(availableColumn.source)
        ) {
          columnsToOmit.push(availableColumn.source);
        }
      });
    }

    if (columnsToOmit.length) {
      setOmit(columnsToOmit);
    }
  }, [currentAvailableColumns, defaultVisibleColumns, setOmit]);

  useDidUpdateEffect(uploadDatagridSettings, [
    JSON.stringify(currentAvailableColumns),
    JSON.stringify(currentColumns),
  ]);

  let expand = null;
  if (resourceAccessList.edit) {
    expand = EditComponent;
  } else if (resourceAccessList.show) {
    expand = ShowComponent;
  }

  return (
    <DatagridConfigurable
      bulkActionButtons={false}
      rowClick={false}
      expand={expand && <ExpandWrapper expandElement={expand} />}
      expandSingle
      preferenceKey={finalPreferenceKey}
      {...rest}
    >
      {visibleChildren}
    </DatagridConfigurable>
  );
};

export default DatagridConfigurableRbac;

interface ExpandWrapperProps {
  expandElement: React.ReactElement;
}

const ExpandWrapper: React.FC<ExpandWrapperProps> = ({
  expandElement,
}): ReactElement => {
  const record = useRecordContext();
  const resource = useResourceContext();

  return React.cloneElement(expandElement, {
    id: record.id,
    record,
    resource,
  });
};
