import { useGetOne } from 'react-admin';
import pick from 'lodash/pick';
import { getContext, UserContext } from '../../../provider/userContext';
import { RESOURCE_PROJECT } from '../../constants';
import { ProjectSettings } from '../types';

const useCurrentProjectSettings = (): {
  isLoading: boolean;
  currentProjectSettings: ProjectSettings;
} => {
  const ctx: UserContext = getContext();

  const { data, isLoading } = useGetOne(
    RESOURCE_PROJECT,
    { id: ctx?.projectId },
    {
      enabled: !!ctx?.projectId,
    }
  );

  return {
    isLoading: isLoading,
    currentProjectSettings: data
      ? pick(data, [
          'inheritCertSignoffsSys',
          'inheritCertSignOffsSs',
          'inheritCertSignoffsComponent',
          'enforceCertGatingSys',
          'enforceCertGatingSs',
          'enforceCertGatingComponent',
          'isDuplicateItrEnabled',
          'isItrPunchForcedCreationEnabled',
          'isPresPunchForcedCreationEnabled',
          'isPinCodeEntryEnabled',
          'isItrMobile',
          'isPresMobile',
          'presAutomation',
          'startDate',
          'plannedStopDate',
        ])
      : {},
  };
};

export default useCurrentProjectSettings;
