import React, { ReactElement, useMemo } from 'react';
import { AutocompleteInput } from 'react-admin';
import { getContext, UserContext } from '../../../provider/userContext';
import { required } from '../../../utils/UtilityFunctions';
import { RESOURCE_ACTIVITY } from '../../constants';
import AutocompleteReferenceInput from '../../common/AutocompleteReferenceInput';

interface ActivityInputProps
  extends React.ComponentProps<typeof AutocompleteInput> {
  isRequired?: boolean;
  idToFilterOut?: string | number;
}

const ActivityInput: React.FC<ActivityInputProps> = ({
  isRequired,
  validate: validateFromProps,
  optionText,
  idToFilterOut,
  ...rest
}): ReactElement => {
  let validate = validateFromProps;
  validate = validate ?? [];
  validate = Array.isArray(validate) ? validate : [validate];
  if (isRequired) {
    validate.push(required());
  }

  const ctx: UserContext = getContext();
  const requestParams = useMemo(() => {
    return {
      filter: {
        projectId: ctx?.projectId,
        ...(idToFilterOut && { id_neq: idToFilterOut }),
      },
      sort: { field: 'activity', order: 'ASC' as const },
    };
  }, [ctx?.projectId, idToFilterOut]);

  return (
    <AutocompleteReferenceInput
      {...rest}
      resource={RESOURCE_ACTIVITY}
      requestParams={requestParams}
      name="activity_inc"
      optionText={optionText || 'activity'}
      validate={validate}
      includeFields={['id', 'activity', 'description']}
    />
  );
};

export default ActivityInput;
