import moment from 'moment';
import { isEmpty, isNotEmpty } from '../../utils/UtilityFunctions';
import { Task } from './components/gantt/types';
import {
  Activity,
  ModifiedData,
  Predecessor,
  PredecessorsModifiedData,
} from './types';

const mapPredecessorsToDependency = (
  predecessors: Predecessor[]
): string | undefined => {
  if (!predecessors) return undefined;

  return predecessors
    .map((item) => {
      const { predecessorId, type, offset, offsetUnit } = item;
      const predecessor = `${predecessorId}${type}`;
      if (isNotEmpty(offset) && offset != 0 && isNotEmpty(offsetUnit)) {
        const sign = offset > 0 ? '+' : '';
        return `${predecessor}${sign}${offset} ${offsetUnit.toLowerCase()}`;
      } else {
        return predecessor;
      }
    })
    .join(',');
};

// Removes extra fields for Activity item. For example fields: downloadUrl, certDetails
export const removeExtraFieldsFromActivity = ({
  id,
  activity,
  plannedStartDate,
  plannedDate,
  duration,
  durationUnit,
  percentComplete,
  predecessors,
  parentId,
}: Activity): Activity => ({
  id,
  activity,
  plannedStartDate,
  plannedDate,
  duration,
  durationUnit,
  percentComplete,
  predecessors,
  parentId,
});

export const mapActivityToTask = (record: Activity): Task => {
  const {
    id,
    activity,
    plannedStartDate,
    plannedDate,
    duration,
    durationUnit,
    percentComplete,
    predecessors,
    parentId,
  } = record;
  return {
    id,
    name: activity,
    startDate: plannedStartDate,
    endDate: plannedDate,
    duration,
    durationUnit: durationUnit?.toLowerCase(),
    progress: percentComplete,
    dependency: mapPredecessorsToDependency(predecessors),
    parentID: parentId,
  };
};

export const mapTaskToActivity = (task: Task): Activity => {
  const {
    id,
    name,
    startDate,
    endDate,
    duration,
    durationUnit,
    progress,
    parentID,
  } = task;
  return {
    id,
    activity: name,
    plannedStartDate: startDate,
    plannedDate: endDate,
    duration,
    durationUnit: durationUnit.toUpperCase(),
    percentComplete: progress,
    parentId: parentID,
  };
};

/*
 Parses dependency string representation like '1FF-2 days' into 4 parts:
 predecessorId, type, offset?, offsetUnit?
 */
const REGEX_DEPENDENCY =
  /(\d+)(FS|FF|SS|SF)(\s*[+-]?\d+)?\s*(day|hour|minute)?/;

const mapDependencyToPredecessors = (
  activityId: number,
  dependency: string
): Predecessor[] => {
  if (isEmpty(dependency)) return [];

  return dependency.split(',').map((item) => {
    const [, id, type, offset = undefined, offsetUnit = undefined] =
      REGEX_DEPENDENCY.exec(item.trim());
    return {
      activityId,
      predecessorId: parseInt(id),
      type,
      offset: offset ? parseInt(offset) : null,
      offsetUnit: offsetUnit ? offsetUnit.toUpperCase() : null,
    };
  });
};

const predecessorKey = (activityId, predecessorId): string => {
  return `${activityId}-${predecessorId}`;
};

const getPredecessorsModifiedData = (
  initialData: Activity[],
  changes: Task[]
): PredecessorsModifiedData => {
  const initialPredecessors = initialData
    .map(({ predecessors }) => predecessors)
    .flat();

  const changesPredecessors = changes
    .map(({ id, dependency }) => mapDependencyToPredecessors(id, dependency))
    .flat();

  const changesActivityIds = changes.map(({ id }) => id);

  const changesPredecessorsKeys = changesPredecessors.map((item) =>
    predecessorKey(item.activityId, item.predecessorId)
  );
  const initialPredecessorsKeys = initialPredecessors.map((item) =>
    predecessorKey(item.activityId, item.predecessorId)
  );

  const deletedPredecessors = initialPredecessors
    .filter((item) => changesActivityIds.includes(item.activityId))
    .filter(
      (item) =>
        !changesPredecessorsKeys.includes(
          predecessorKey(item.activityId, item.predecessorId)
        )
    );

  const createdPredecessors = changesPredecessors.filter(
    (item) =>
      !initialPredecessorsKeys.includes(
        predecessorKey(item.activityId, item.predecessorId)
      )
  );

  const updatedPredecessors = changesPredecessors
    .filter((item) =>
      initialPredecessorsKeys.includes(
        predecessorKey(item.activityId, item.predecessorId)
      )
    )
    .map((item) => {
      const initial = initialPredecessors.find(
        ({ activityId, predecessorId }) =>
          activityId === item.activityId && predecessorId === item.predecessorId
      );
      return { id: initial.id, ...item };
    });

  return {
    deleted: deletedPredecessors.map(({ id }) => id),
    created: createdPredecessors,
    updated: updatedPredecessors,
  };
};

export const getModifiedDataToSave = (
  initialData: Activity[],
  changes: Task[]
): ModifiedData => {
  return {
    activities: changes.map(mapTaskToActivity),
    predecessors: getPredecessorsModifiedData(initialData, changes),
  };
};

export const checkTasksAreEqual = (
  initialTask: Task,
  updatedTask: Task
): boolean =>
  initialTask.id === updatedTask.id &&
  initialTask.name === updatedTask.name &&
  (initialTask.duration === updatedTask.duration ||
    (initialTask.duration === null && updatedTask.duration === 0)) &&
  initialTask.durationUnit === updatedTask.durationUnit &&
  (initialTask.progress === updatedTask.progress ||
    (initialTask.progress === null && updatedTask.progress === 0)) &&
  initialTask.parentID === updatedTask.parentID &&
  initialTask.dependency === updatedTask.dependency &&
  moment(initialTask.startDate).isSame(updatedTask.startDate, 'day') &&
  moment(initialTask.endDate).isSame(updatedTask.endDate, 'day');
